import { Token } from '@uniswap/sdk';
import React, { useCallback } from 'react';
import Modal from '../Modal';
import { ImportToken } from 'components/SearchModal/ImportToken';

export default function TokenWarningModal({
  isOpen,
  tokens,
  onConfirm,
}: {
  isOpen: boolean;
  tokens: Token[];
  onConfirm: () => void;
}) {
  const handleDismiss = useCallback(() => null, []);

  return (
    <Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
      <ImportToken tokens={tokens} handleCurrencySelect={onConfirm} />
    </Modal>
  );
}
